<template>
        <Drawer v-model:visible='theModel' position='right' class='slidePanel editPanelProgclient' :dismissable='false'>
                <template #header>
                        <div>{{ header }}</div>
                </template>

                <div class='editingbody px-3'>
                        <div>Секретный код: <b>{{ progclient.secret }}</b></div>
                        <UInputField type='input' v-model='progclient.name' name='Название программного клиента' :asterics=true :limit=100 class='mt-3'></UInputField>
                        <UListBox name="Программный продукт" :options='products' v-model='progclient.product_id' :asterics='true' class="mt-3" style='height:350px'/>
                </div>

                <div class='downButtonPanel'>
                        <Button @click='save' v-if='!isCreate' :disabled='disableSave'>Сохранить</Button>
                        <Button @click='save' v-if='isCreate' :disabled='disableSave'>Создать</Button>
                </div>
        </Drawer>
</template>

<script setup lang='ts'>
        import { computed } from 'vue';
        import { CProgclient } from '@/interfaces';
        import controllerEditor from '@/editors/controller/controllerEditor';
        import { BillingStore } from '@/ts/store';
        import common from '@/ts/common';

        const props = defineProps<{ modelValue: boolean, editingData: CProgclient }>()
        const emit = defineEmits(['update:modelValue', 'save'])
        let cStore = BillingStore()

        const products = computed(() => cStore.products)
        const progclient = computed(() => props.editingData)

        const {
                theModel,
                header,
                save,
                editingData,
                disableSave,
                isCreate
        } = controllerEditor({
                props, emit,
                headerCreate: 'Создание программного клиента',
                headerEdit: 'Редактирование программного клиента ' + progclient.value.id
        })

        if(isCreate.value) {
                progclient.value.secret = common.createSecretCode()
        }

</script>
