<template>
    <div class="filterPanel mt-2">
        <div class="flex">
            <UInputField name="Поиск по названию" type="input" class="lineInput w-4" v-model="curFilter.name"></UInputField>
            <UInputField name="По ID" type="input" class="lineInput w-2 ml-4" v-model="curFilter.id"></UInputField>
            <UDropdownBox name='Программный продукт' :options='products' v-model='curFilter.product_id' class='ml-4 w-4' styleDropDown='width:100%' :showClear='true'></UDropdownBox>
            <UDropdownBox name='Статус лицензии' :options='statuses_license' v-model='curFilter.status' class='ml-4 w-2' styleDropDown='width:100%' :showClear='true'></UDropdownBox>
        </div>

        <div class="flex mt-3">
            <UDropdownBox name='Период лицензии' :options='period_license' v-model='curFilter.access_period' class='w-2' styleDropDown='width:100%' :showClear='true'></UDropdownBox>
            <div class='ml-4 w-2'>
                <label>Дата начала</label>
                <DatePicker show-button-bar v-model='active_from' class='w-full' dateFormat='dd.mm.yy' @clear-click="active_from = ''"/>
            </div>
            <div class='ml-4 w-2'>
                    <label>Дата окончания</label>
                    <DatePicker show-button-bar v-model='active_to' class='w-full' dateFormat='dd.mm.yy'  @clear-click="active_to = ''"/>
            </div>
            <div class="buttonPanel flex-shrink-0 ml-4 mt-3 w-6" style="width: 340px; height: 36px;">
                <Button class="p-button-text" @click="clearFilterLocal">Сбросить</Button>
                <Button class="ml-4" @click="applyFilter">Применить</Button>
                <Button class="ml-4 p-button-outlined" @click="hide">Скрыть</Button>
            </div>
        </div>

        
    </div>
</template>

<script setup lang="ts">
    import { computed, ref, watch } from 'vue';
    import {CFilterLicense} from "@/interfaces";
    import controllerFilter from "@/panels/controller/controllerFilter";
    import common from '@/ts/common';
    import { BillingStore } from '@/ts/store';

    const emit = defineEmits(["hide", "apply", "clear"])

    let cStore = BillingStore()

    const products = computed(() => cStore.products)
    const statuses_license = computed(() => cStore.statuses_license);
    const period_license = computed(() => cStore.period_license);

    const {
        curFilter,
        hide, applyFilter, clearFilter
    } = controllerFilter({
        emit,
        classFilter : CFilterLicense,
    })

    const active_from = ref<Date|null>(null)
    const active_to = ref<Date|null>(null)

    const clearFilterLocal = () => {
            active_from.value = null;
            active_to.value = null;
            clearFilter();
    }

    watch(active_from, () => curFilter.value.active_from = common.dateFormatSave(active_from.value))
    watch(active_to, () => curFilter.value.active_to = common.dateFormatSave(active_to.value))
</script>
