import {CFilterIdName} from "@/interfaces";
import common from '@/ts/common';

export class CFilterLicense extends CFilterIdName {
    contract_id: number|null
    status : number|null
    active_from : string
    active_to : string
    product_id: number|null
    access_period: number|null

    constructor(flt : CFilterLicense|null = null) {
        super(flt)

        if(flt === null) {
            this.contract_id = null
            this.status = null
            this.active_from = ''
            this.active_to = ''
            this.product_id = null
            this.access_period = null
        } else {
            this.contract_id = flt.contract_id
            this.status = flt.status
            this.active_from = flt.active_from
            this.active_to = flt.active_to
            this.product_id = flt.product_id
            this.access_period = flt.access_period
        }
    }
}
