<template>
        <div class='frameWindow contractsWindow' v-if='loaded'>
                <h3>Контракты</h3>
                <Tabs class='objectsTab' value='0' @update:value='updateTab'>
                        <TabList>
                                <Tab value='0'>Все</Tab>
                                <Tab value='1' @click='hideFilter = false'>Фильтр</Tab>
                        </TabList>
                </Tabs>

                <FilterContractPanel v-show='isViewFilter' @hide='() => hideFilter = true' @apply='applyFilter' @clear='clearFilter'></FilterContractPanel>

                <div class='bodyFrame'>
                        <div class='w-full flex flex-column h-full'>
                                <ListBase :columns='columns' :apiLoader='contractApi' v-model='selectedObject'
                                          class='listBase'
                                          :sortField="{field:'id', direction : 'DESC'}"
                                          :editingRow='false'
                                          :elipsisSupport='true'
                                          :custom_filters='customFilter'
                                          :hard_filters='hardFilter'
                                          ref='list'
                                          @addRow='addRow'
                                          @loaded='onLoadedList'>
                                        <template #buttons='{data}'>
                                                <Button icon='pi pi-pencil' class='mr-2' @click='editRow(data.data)' />
                                                <Button icon='pi pi-trash' class='red_button mr-2'
                                                        @click='removeRow(data.data)' />
                                        </template>
                                </ListBase>
                        </div>
                </div>
        </div>

        <EditorContract v-model='visibleEditor' :editingData='editingData' :additionalData="additionalEditorData" v-if='editingData' @save='save'></EditorContract>
</template>

<script setup lang='ts'>
        import controller from '@/window/controller/controller';
        import { CAdditionDataContract, CContract, CFilterContract } from '@/interfaces';
        import { contractApi } from '@/ts/api';
        import ListBase from '@/components/ListBase.vue';
        import EditorContract from '@/editors/EditorContract.vue';
        import FilterContractPanel from '@/panels/FilterContractPanel.vue';
        import common from '@/ts/common';

        const columns = [
                { id: 'id', header: 'ID', style: 'width : 75px; flex-shrink:0', sortable: true },
                { id: 'name', header: 'Название', style: 'width : 25%', sortable: true },
                { id: 'actor', header: 'Актор', style: 'width : 15%', sortable: true },
                { id: 'client', header: 'Контрагент', style: 'width : 15%', sortable: true },
                { id: 'active_from', header: 'Начало действия', style: 'width : 15%', sortable: true},
                { id: 'active_to', header: 'Окончание действия', style: 'width : 15%', sortable: true },
                { id: 'status_view', header: 'Статус', style: 'width : 10%', sortable: true },
                { id: 'buttons', header: 'Кнопки', style: 'width : 140px; flex-shrink:0' }
        ];

        const onLoadedList = (event : {records:[], total:number}) => {
                (event.records as CContract[]).forEach((r : CContract)  => {
                        r.active_from = common.dateFormatView(r.active_from) as string
                        r.active_to = common.dateFormatView(r.active_to) as string
                })
        }

        const {
                loaded, list, selectedObject, customFilter, hardFilter,
                activeTab, updateTab,
                hideFilter, isViewFilter, applyFilter, clearFilter,
                visibleEditor, editingData, additionalEditorData, addRow, editRow, save, removeRow
        } = controller({
                filterTabIndex: '1',
                supportTab: true,
                supportPrepareEdit: false,
                classFilter: CFilterContract,
                classRow: CContract,
                classAdditionalData: CAdditionDataContract,
                api: contractApi,
                reloadSaveFields: ['name', 'actor_id', 'client_id'],
                removeMessage: 'Вы действительно хотите удалить Контракт?'
        });

</script>
