<template>
        <div class="UListBox">
                <div class='labelline'><label v-if="name">{{ name }}</label><span class="asterics" v-if="asterics">* </span></div>
                <Listbox v-model="theModel" :options='options' :optionLabel="optionLabel" :optionValue="optionValue" ref="list"></Listbox>
        </div>
</template>

<script setup lang="ts">
        import { nextTick, onMounted, ref } from 'vue';
        import {useVModel} from '@vueuse/core'
        import common from '@/ts/common';

        const props = withDefaults(
                defineProps<{
                        name?: string | null
                        modelValue?: string | number
                        // eslint-disable-next-line
                        options?: any[]
                        asterics?: boolean,
                        optionLabel ?: string,
                        optionValue ?: string
                }>(),
                {
                        name: null,
                        modelValue: '',
                        options: [],
                        asterics : false,
                        optionLabel : "name",
                        optionValue : "id"
                }
        )
        const emit = defineEmits(['update:modelValue'])
        const theModel = useVModel(props, 'modelValue', emit)

        const list = ref()

        onMounted(async () => {
                await nextTick()
                common.scrollListToSelect(list.value)
        })

</script>
