<template>
        <Drawer v-model:visible='theModel' position='right' class='slidePanel editPanelClient' :dismissable='false'>
                <template #header>
                        <div>{{ header }}</div>
                </template>

                <div class='editingbody px-3'>
                        <UInputField type='input' v-model='client.name' name='Название клиента' :asterics=true :limit=100></UInputField>
                        <span class='mt-3 mr-2'>Компания</span>
                        <Checkbox v-model='client.is_company' :binary='true' class='mt-3' />

                        <UInputField type='input' v-model='client.inn' name='ИНН' :asterics=true class='mt-3 line' :limit=100></UInputField>
                        <UInputField v-model='client.comment' name='Комментарий' class='mt-3'></UInputField>
                </div>

                <div class='downButtonPanel'>
                        <Button @click='save' v-if='!isCreate' :disabled='disableSave'>Сохранить</Button>
                        <Button @click='save' v-if='isCreate' :disabled='disableSave'>Создать</Button>
                </div>
        </Drawer>
</template>

<script setup lang='ts'>
        import { computed } from 'vue';
        import { CClient } from '@/interfaces';
        import controllerEditor from '@/editors/controller/controllerEditor';

       const props = defineProps<{ modelValue: boolean, editingData: CClient }>()
        const emit = defineEmits(['update:modelValue', 'save'])

        const client = computed(() => props.editingData)

        const {
                theModel,
                header,
                save,
                editingData,
                disableSave,
                isCreate
        } = controllerEditor({
                props, emit,
                headerCreate: 'Создание клиента',
                headerEdit: 'Редактирование клиента ' + client.value.id
        })

</script>
