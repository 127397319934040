import { CContract, CEditing, CEditTemplate, IListNameElement } from '@/interfaces';
import common from '@/ts/common'

export class CLicense extends CEditing {
        name: string
        is_keys: boolean
        is_access_unlimited: boolean
        contract_id: number
        priority: boolean
        status: number
        active_from: string
        active_to: string
        access_quantity: number
        access_granted: number
        access_expiration_at: string|null
        access_period: number
        scope_json: string
        product_id: number
        comment: string

        constructor(data: CLicense | null = null) {
                super(data)

                if (data == null) {
                        this.name = ''
                        this.is_keys = false
                        this.is_access_unlimited = false
                        this.contract_id = 0
                        this.priority = false
                        this.status = 0
                        this.active_from = common.dateFormatView(new Date())
                        this.active_to = common.dateFormatView(new Date())
                        this.access_quantity = 0
                        this.access_granted = 0
                        this.access_expiration_at = null //common.dateFormatView(new Date())
                        this.access_period = 0
                        this.scope_json = ''
                        this.product_id = 0
                        this.comment = ''
                } else {
                        this.name = data.name
                        this.is_keys = data.is_keys
                        this.is_access_unlimited = data.is_access_unlimited
                        this.contract_id = data.contract_id
                        this.priority = data.priority
                        this.status = data.status
                        this.active_from = data.active_from
                        this.active_to = data.active_to
                        this.access_quantity = data.access_quantity
                        this.access_granted = data.access_granted
                        this.access_expiration_at = data.access_expiration_at
                        this.access_period = data.access_period
                        this.scope_json = data.scope_json
                        this.product_id = data.product_id
                        this.comment = data.comment
                }
        }

        fillForSave(dataTo: CLicense) {
                dataTo.name = this.name.trim()
                dataTo.contract_id = this.contract_id
                dataTo.priority = this.priority
                dataTo.status = this.status
                dataTo.active_from = this.active_from
                dataTo.active_to = this.active_to
                dataTo.access_quantity = this.access_quantity
                dataTo.access_granted = this.access_granted
                dataTo.access_expiration_at = this.access_expiration_at
                dataTo.access_period = this.access_period
                dataTo.scope_json = this.scope_json
                dataTo.product_id = this.product_id
                dataTo.comment = this.comment.trim()
        }

        isDisableSave(): boolean {
                return this.name.trim() === '' || !(this.contract_id && this.product_id)
        }
}

export class CLicenseData {
        name : string = ''
        actor: string = ''
        client : string = ''
        product : string = ''

        constructor(data: CLicenseData | null = null) {
            if (!(data == null)) {
                this.name = data.name
                this.actor = data.actor
                this.client = data.client
                this.product = data.product
            }
        }

        loadContract(data : {name : string, actor : string, client : string}) {
                this.name = data.name
                this.actor = data.actor
                this.client = data.client
        }

        loadProduct(data : {product : string}) {
                this.product = data.product
        }
}

export class CDetailKeys {
    count: number
    status: string

    constructor(data: CDetailKeys) {
            this.count = data.count
            this.status = data.status
    }
}


export class CAdditionDataLicense extends CEditTemplate {
        license_data: CLicenseData
        access_keys: CDetailKeys[]

        constructor(data: CAdditionDataLicense) {
                super(data)
                this.license_data = data.license_data
                this.access_keys = data.access_keys
        }
}
