import { CFilterIdName } from '@/interfaces';
import common from '@/ts/common';

export class CFilterContract extends CFilterIdName {
        actor_id: number | null;
        client_id: number | null;
        status: number | null;
        active_from: string;
        active_to: string;
        search: string|null;

        constructor(flt: CFilterContract | null = null) {
                super(flt);

                if (flt === null) {
                        this.actor_id = null;
                        this.client_id = null;
                        this.active_from = '';
                        this.active_to = '';
                        this.status = null;
                        this.search = null;
                } else {
                        this.actor_id = flt.actor_id;
                        this.client_id = flt.client_id;
                        this.active_from = flt.active_from;
                        this.active_to = flt.active_to;
                        this.status = flt.status;
                        this.search = flt.search;
                }
        }

}
