<template>
        <div class='frameWindow licensesWindow' v-if='loaded'>
                <h3>Лицензии</h3>
                <Tabs class='objectsTab' value='0' @update:value='updateTab'>
                        <TabList>
                                <Tab value='0'>Все</Tab>
                                <Tab value='1' @click='hideFilter = false'>Фильтр</Tab>
                        </TabList>
                </Tabs>

                <FilterLicensePanel v-show='isViewFilter' @hide='() => hideFilter = true' @apply='applyFilter' @clear='clearFilter'></FilterLicensePanel>

                <div class='bodyFrame'>
                        <div class='w-full flex flex-column h-full'>
                                <ListBase :columns='columns' :apiLoader='licenseApi' v-model='selectedObject'
                                          class='listBase'
                                          :sortField="{field:'id', direction : 'DESC'}"
                                          :editingRow='false'
                                          :elipsisSupport='true'
                                          :custom_filters='customFilter'
                                          :hard_filters='hardFilter'
                                          ref='list'
                                          @addRow='addRow'
                                          @loaded='onLoadedList'>
                                        <template #name='{data}'>
                                            <span class="font-level-1">{{ data.data.name }}</span><br>
                                            <span class="font-level-2">id {{ data.data.id }} создана: 01.01.2024</span>
                                        </template>
                                        <template #date='{data}'>
                                            <span class="font-level-1">Действует с {{ data.data.active_from }} по {{ data.data.active_to }}</span><br>
                                            <span class="font-level-2" v-if="data.data.access_expiration_at">Доступ до {{ data.data.access_expiration_at }}</span>
                                        </template>
                                        <template #contract='{data}'>
                                            <span class="font-level-1">{{ data.data.contract_name }}</span><br>
                                            <span class="font-level-2-bold">{{ data.data.client_name }}</span><br>
                                            <span class="font-level-2">{{ data.data.product_name }}</span>
                                        </template>
                                        <template #access='{data}'>
                                            <span class="font-level-1">
                                                <i class="pi pi-key mr-1" v-if="data.data.is_keys" style="opacity:1"></i>
                                                <i class="pi pi-key mr-1" v-if="!data.data.is_keys" style="opacity:0.25"></i>
                                                <span v-if="data.data.is_access_unlimited">
                                                    Выдано: {{ data.data.access_granted }} из {{ data.data.access_quantity }}
                                                </span>
                                                <span v-if="!data.data.is_access_unlimited">
                                                    Выдано: {{ data.data.access_granted }}
                                                </span>
                                            </span>
                                        </template>
                                        <template #is_keys='{data}'>
                                            {{ (data.data.is_keys) ? 'Есть' : 'Нет' }}
                                        </template>

                                        <!--template #name1='{data}'>
                                            {{ data.data.name }}<br>
                                            {{ data.data.contract_name }}<br>
                                            {{ (data.data.priority) ? 'Приоритетная' : ''}}
                                        </template-->


                                        <template #buttons='{data}'>
                                                <Button icon='pi pi-pencil' class='mr-2' @click='editRow(data.data)' />
                                                <Button icon='pi pi-trash' class='red_button mr-2'
                                                        @click='removeRow(data.data)' />
                                        </template>
                                </ListBase>
                        </div>
                </div>
        </div>

        <EditorLicense v-model='visibleEditor' :editingData='editingData' :additionalEditorData='additionalEditorData' v-if='editingData' @save='save'></EditorLicense>
</template>

<script setup lang='ts'>
        import controller from '@/window/controller/controller';
        import { CLicense, CFilterLicense, CAdditionDataLicense } from '@/interfaces';
        import { licenseApi } from '@/ts/api';
        import ListBase from '@/components/ListBase.vue';
        import EditorLicense from '@/editors/EditorLicense.vue';
        import FilterLicensePanel from '@/panels/FilterLicensePanel.vue';
        import { BillingStore } from '@/ts/store';
        import common from '@/ts/common';

        const columns = [
                /*{ id: 'id', header: 'ID', style: 'width : 75px; flex-shrink:0', sortable: true },*/
                { id: 'name', header: 'Название [ID]', style: 'width : 25%', sortable: true },
                { id: 'date', header: 'Дата', style: 'width : 25%', sortable: true },
                { id: 'contract', header: 'Контракт', style: 'width : 20%', sortable: true },
                { id: 'access', header: 'Доступы', style: 'width : 20%', sortable: true },
                /*{ id: 'contract_name', header: 'Контракт', style: 'width : 20%', sortable: true },
                { id: 'priority', header: 'Приоритет в договоре', style: 'width : 20%', sortable: true },
                { id: 'license_status_name', header: 'Статус', style: 'width : 15%', sortable: true },
                { id: 'access_period_name', header: 'Период действия', style: 'width : 15%', sortable: true },*/
                { id: 'buttons', header: 'Кнопки', style: 'width : 140px; flex-shrink:0' }
        ]

        const onLoadedList = (event : {records:[], total:number}) => {
                (event.records as CLicense[]).forEach((r : CLicense)  => {
                        r.active_from = common.dateFormatView(r.active_from) as string
                        r.active_to = common.dateFormatView(r.active_to) as string
                        r.access_expiration_at = common.dateFormatView(r.access_expiration_at)
                })
        }

        let cStore = BillingStore();

        const {
                loaded, list, selectedObject, customFilter, hardFilter,
                activeTab, updateTab,
                hideFilter, isViewFilter, applyFilter, clearFilter,
                visibleEditor, editingData, additionalEditorData, addRow, editRow, save, removeRow
        } = controller({
                filterTabIndex: '1',
                supportTab: true,
                supportPrepareEdit: true,
                classAdditionalData : CAdditionDataLicense,
                classFilter: CFilterLicense,
                classRow: CLicense,
                api: licenseApi,
                reloadSaveFields: ['name', 'product_id', 'contract_id', 'status', 'access_period'],
                removeMessage: 'Вы действительно хотите удалить Лицензию?'
        })

</script>
