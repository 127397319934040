<template>
        <div class="listBase" ref="baselist">
                <DataTable
                        :class="{ 'empty' : !totalRecords}"
                        :value="objects" class="p-datatable-objects"
                        :scrollable="true"
                        dataKey="id" :rowHover="false" v-model:selection="selectedObject" :loading="loading"
                        :lazy="true" :paginator="true" :rows="rows" :totalRecords="totalRecords"
                        :rowsPerPageOptions="[10, 20, 30, 50]"
                        :first="loadParams.first"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Показано от {first} до {last} из {totalRecords} записей"
                        selectionMode = "single"
                        responsiveLayout="scroll"
                        :resizableColumns="true" columnResizeMode="fit"
                        @rowSelect="rowSelect"
                        @rowDblclick="rowDblclick"
                        @page="onPage($event)"
                        @sort="onSort"
                        @rowReorder="onRowReorder"
                >

                        <template #empty>Объектов не найдено</template>
                        <template #loading>Идет загрузка. Ждите.</template>
                        <template #paginatorend>
                                <slot name="paginatorend">
                                        <div class="pagginatorButtons">
                                                <div v-if="elipsisSupport" class="elipsisButtons">
                                                        <span :class="{active : activeFull}" @click="activateFull(false)">Краткий</span> | <span :class="{active : !activeFull}" @click="activateFull(true)">Полный</span>
                                                </div>
                                                <slot name="paginatorendbuttons">
                                                        <Button type="button" @click="addRow" v-if='enableAdd'>Добавить</Button>
                                                        <Button type="button" :disabled="disableRemoveButton" @click="removeSelect" v-if="multipleRow" class="ml-2">Удалить</Button>
                                                </slot>
                                        </div>
                                </slot>
                        </template>

                        <Column selectionMode="multiple" style="max-width: 46px" v-if="multipleRow"></Column>
                        <template v-for="col in columns" :key="col.id">
                                <Column rowReorder style="width: 46px;  flex-shrink: 0" :reorderableColumn="false" v-if="col.id == 'sortingRow'" />
                                <Column :field="col.id" :header="col.header" :sortable="col.sortable" :style="col.style" v-else>
                                        <template #body="{data}" >
                                                <slot :name="col.id" :data="{elipsis, data}">
                                                       <div class="cell_text" :class="elipsis">{{ data[col.id] }}</div>
                                                </slot>
                                        </template>
                                </Column>
                        </template>
                        <Column :style="styleButton" bodyStyle="text-align:center" header="Кнопки" v-if="editingRow">
                                <template #body="{data}">
                                        <Button icon="pi pi-pencil" class="mr-2" @click="editRow(data)" v-if="editEnable" :disabled="isDisabled"/>
                                        <Button icon="pi pi-history" class="p-button-success mr-2" @click="restoreRow(data)" v-if="restoreEnable" :disabled="isDisabled"/>
                                        <slot name="appendButtonBeforeTrash" :data="{data}"></slot>
                                        <Button icon="pi pi-trash" class="red_button" @click="removeRow(data)" v-if="removeEnable" :disabled="isDisabled"/>
                                        <slot name="appendButton" :data="{data}"></slot>
                                </template>
                        </Column>
                        <Column rowReorder style="width: 46px;  flex-shrink: 0" :reorderableColumn="false" v-if="sortingRow" />
                </DataTable>
        </div>
</template>

<script setup lang="ts">
import {computed, nextTick, ref, watch} from "vue";
        import {CApi} from "@/ts/api";
        import {IColumnData, getTColumnData, CFilter, IListElement, TSortData} from "@/interfaces"
        import {useVModel} from "@vueuse/core";
        import {DataTablePageEvent, DataTableRowReorderEvent} from "primevue/datatable";
import common from '@/ts/common';

        const props = withDefaults(defineProps<{
                modelValue ?: IListElement|null,
                rowsPage ?: number|null,
                apiLoader: CApi,
                url?: string,
                columns?: IColumnData[],
                sortField?: TSortData,
                sortingRow?: boolean,
                custom_filters?:CFilter|null,
                hard_filters?:CFilter,
                editingRow?: boolean,
                multipleRow?: boolean,
                editEnable?: boolean,
                removeEnable?: boolean,
                restoreEnable?: boolean,
                elipsisSupport?: boolean,
                styleButton ?: string,
                enableAdd ?: boolean
        }>(), {
                url : "loadList",
                columns : () => getTColumnData(),
                sortField : () => { return { field:"id", direction : "DESC"}},
                prefs : null,
                custom_filters : null,
                hard_filters : () => { return {}},
                sortingRow : false,
                search_fields : null,
                editingRow : false,
                multipleRow : false,
                editEnable : true,
                removeEnable : true,
                restoreEnable : false,
                elipsisSupport : false,
                styleButton : "width: 102px; flex-shrink: 0",
                rowsPage : 10,
                enableAdd : true
        })

        const emit = defineEmits(['rowSelect', 'rowDblclick', 'update:modelValue', 'update:rowsPage', 'rowReorder', 'editRow', 'removeSelect', 'addRow', 'removeRow', 'restoreRow', 'loaded'])
        const selectedObject = useVModel(props, 'modelValue', emit)
        const modelRowsPage = useVModel(props, 'rowsPage', emit)
        const baselist = ref()

        const rows = ref(/*parseInt(cookies.get("rowsList")) || */10)
        if(modelRowsPage.value !== rows.value) modelRowsPage.value =  rows.value

        //        const currectSearch = ref(<TSearch|null>null)

        const currentSortField = ref(props.sortField)

        const totalRecords = ref(0);
        const loading = ref(true);
//        const osStore = OsStore();
        //const presortOrder = computed(() => (currentSortField.value.direction == "ASC" ? 1 : -1))

        const loadParams = ref({first : 0, rows : rows.value, page: 0});
        const objects = ref();
        const onLoadList = async (id : number|null = null) => {
                loading.value = true;
                if(id == null) id = (selectedObject.value?.id||null);
                let data = await props.apiLoader.loadList(props.url, loadParams.value.first, loadParams.value.rows, currentSortField.value, props.custom_filters, props.hard_filters/*, currectSearch.value*/)
                objects.value = data.records;
                totalRecords.value  = data.total;
                emit("loaded", {records : objects.value, total : totalRecords.value})
                loading.value = false;
                if(id) {
                        selectedObject.value = objects.value.find(el => el.id == id)
                        await nextTick()
                        if(selectedObject.value) {
                                //common.scrollDataTableToSelect(baselist.value)
                        }
                }
        }
        const onLoadListStart = () => {
                loadParams.value.first = 0
                loadParams.value.page = 0
                onLoadList()
        }

        const onPage = (event:DataTablePageEvent, selectId : number|null|undefined) => {
                loadParams.value = {first : event.first, rows : event.rows, page: event.page }
                rows.value = event.rows
                modelRowsPage.value = event.rows
                //cookies.set("rowsList", event.rows + '')
                onLoadList(selectId);
        }

        const onSort = event => {
                currentSortField.value.field = event.sortField
                currentSortField.value.direction = event.sortOrder > 0 ? "ASC" : "DESC"
                loadParams.value.first = event.first
                onLoadList()
        }

        const rowSelect = () => {
                emit("rowSelect", selectedObject.value)
        }
        const rowDblclick = () => emit("rowDblclick", selectedObject.value)

        onLoadList();

        const onRowReorder = (event:DataTableRowReorderEvent) => {
                objects.value = event.value;
                emit("rowReorder", event)
        }

        const editRow = data => {
                selectedObject.value = data
                emit("editRow", data)
        }
        const removeRow = data => {
                emit("removeRow", data)
                if(selectedObject.value == data) selectedObject.value = null
        }
        const restoreRow = data => {
                emit("restoreRow", data)
                if(selectedObject.value == data) selectedObject.value = null
        }

        const disableRemoveButton = computed(() => {
                if(!props.multipleRow) return true;
                //return (!selectedObject.value || selectedObject.value.length == 0)
                return !selectedObject.value
        })

        const removeSelect = () => emit("removeSelect")
        const addRow = () => emit("addRow")

/*        const search = (data:TSearch) => {
                currectSearch.value = data
                onLoadList();
        }*/

//        const isDisabled = computed(() => common.block.value)
        const activeFull = ref(false)
        const activateFull = (bac:boolean) => activeFull.value = bac
        const elipsis = computed(() => { return {elipsis : props.elipsisSupport && !activeFull.value} })

        //watch(() => props.hard_filters, () => onLoadListStart(),  { deep: true })
        watch(() => props.custom_filters, () => onLoadListStart())

// @ts-ignore
        defineExpose({onLoadList, onPage, onLoadListStart})
</script>

<style lang="less">
</style>
