export class CFilter {
        constructor(flt : CFilter|null = null) {
                /**/
        }
}

export class CFilterId extends CFilter {
        id:string

        constructor(flt : CFilterId|null = null) {
                super(flt)
                if(flt === null) this.id = ""
                else this.id = flt.id
        }

}
export class CFilterIdName extends CFilterId {
        name:string

        constructor(flt : CFilterIdName|null = null) {
                super(flt)
                if(flt === null) this.name = ""
                else this.name = flt.name
        }
}
