import { TSortData, CFilter } from "@/interfaces";
import  CApiBase  from "@/ts/api/apiBase";
import {nextTick} from "vue";
import common from "@/ts/common";

export class CApi extends CApiBase {
        constructor(url = "") {
                url = url ? url +  "/" : ""
                super("/" + url);
        }

        async loadList(url : string, offset:number, limit:number, sortField:TSortData, filter:CFilter|null, hard_filters:CFilter/*, search:TSearch|null*/) {
                const request = {
                        offset, limit,
                        sort: [sortField],
                        custom_filters: filter,
                        hard_filters: hard_filters,
//                        search : search ? [search] : null
                }
                const {data} = await this.post(this.getRoot(url), { request })
                if(data.error) {
                        return data.error
                }
                return data;
        }


        async searchRowsByName(start:string, page_count : number, param:object) {
                start
                page_count
                param
                return {}
        }


        async getState(atlas_id : number | null = null) {
                return this.post(this.getRoot("getState"), {atlas_id})
        }

        saveFilter(filter : CFilter) {
                filter
        }

        async prepareEdit(id : number|null = null) {
                const {data} = await this.post(this.getRoot("prepareEdit"), {id})
                if(!data) return null
                return data
        }

        save(data : object | Array<object> | null | undefined, path : string) {
                return this.post(this.getRoot("save"), {data, path})
        }

        async get(id : number) {
                const {data} = await this.post(this.getRoot("get"), {id})
                if(!data) return null
                return data
        }

        async remove(id:number) {
                try {
                        return await this.post(this.getRoot("remove"), {id})
                } catch(er:any) {
                        await nextTick();
                        common.viewError(er.response.data.message)
                }
                return {data : null}
        }

        /*async recall(id:number) {
            try {
                    return await this.post(this.getRoot("recall"), {id})
            } catch(er:any) {
                    await nextTick();
                    common.viewError(er.response.data.message)
            }
            return {data : null}
        }*/
}

export const api = new CApi()
