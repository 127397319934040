<template>
        <Drawer v-model:visible="theModel" position="right" class="slidePanel editPanelProduct" :dismissable="false">
                <template #header>
                        <div>{{ header }}</div>
                </template>

                <div class="editingbody px-3">
                    <UInputField type="input" v-model="product.name" name="Название программного продукта" :asterics=true :limit=100></UInputField>
                    <UInputField v-model="product.comment" name="Комментарий" class="mt-3"></UInputField>
                </div>

                <div class="downButtonPanel">
                        <Button @click="save" v-if="!isCreate" :disabled="disableSave">Сохранить</Button>
                        <Button @click="save" v-if="isCreate" :disabled="disableSave">Создать</Button>
                </div>
        </Drawer>
</template>

<script setup lang="ts">
        import {computed} from "vue";
        import {CProduct} from "@/interfaces";
        import controllerEditor from "@/editors/controller/controllerEditor";


        const props = defineProps<{ modelValue: boolean, editingData: CProduct }>()
        const emit = defineEmits(['update:modelValue', "save"])

        const product = computed(() => props.editingData)

        const {
                theModel,
                header,
                save,
                editingData,
                disableSave,
                isCreate
        } = controllerEditor({
                props, emit,
                headerCreate: "Создание программного продукта",
                headerEdit: "Редактирование программного продукта " + product.value.id,
        })

</script>
