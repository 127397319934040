<template>
        <div class="UCheckBox">
                <div class='labelline'><label v-if="name">{{ name }}</label><span class="asterics" v-if="asterics">* </span></div>
                <Checkbox v-model='theModel' :binary='true' @change="change"/>
        </div>
</template>

<script setup lang="ts">
        import {useVModel} from '@vueuse/core'

        const props = withDefaults(
                defineProps<{
                        name?: string | null
                        modelValue?: boolean | number
                        asterics?: boolean
                }>(),
                {
                        name: null,
                        modelValue: false,
                        asterics : false
                }
        )
        const emit = defineEmits(['update:modelValue', 'change'])
        const theModel = useVModel(props, 'modelValue', emit)

        const change = (event) => emit("change", event)
</script>
